import { Collapse, Form, Input, Segmented } from 'antd';
import { AgentDataModel, QuestionDataModel, QueueDataModel } from '../../../scripts/API/APITypes';
import { AgentSelectionComponent, QuestionSelectionComponent, QueueSelectionComponent, ThresholdComponent } from '../common/WidgetSettingsForm';
import { CountType } from './CountWidget';

const { Panel } = Collapse;

export default function CountWidgetSettings(
  t: any,
  queueList: { [key: string]: QueueDataModel },
  selectedQueues: string[],
  setSelectedQueues: (value: string[]) => void,
  questionList: { [key: string]: QuestionDataModel },
  selectedQuestions: string[],
  setSelectedQuestions: (value: string[]) => void,
  agentList: { [key: string]: AgentDataModel },
  selectedAgents: string[],
  setSelectedAgents: (value: string[]) => void,
  availableAlarms: string[],
  openAlarmModal: () => void,
) {
  return (
    <div>
      <Form.Item label={'Label'} name="label">
        <Input />
      </Form.Item>

      <Form.Item label={'Count Type'} name="counttype" tooltip={'Specifies which responses to count.'}>
        <Segmented
          options={[
            {
              label: 'Completed',
              value: CountType.Completed,
            },
            {
              label: 'Offered',
              value: CountType.Offered,
            },
            {
              label: 'Partially Completed',
              value: CountType.Partial,
            },
          ]}
        />
      </Form.Item>

      <Collapse>
        <Panel header="Filters" key="1">
          {QueueSelectionComponent(t, queueList, selectedQueues, setSelectedQueues)}
          {QuestionSelectionComponent(t, questionList, selectedQuestions, setSelectedQuestions)}
          {AgentSelectionComponent(t, agentList, selectedAgents, setSelectedAgents)}
        </Panel>

        <Panel header="Alerting" key="2">
          <ThresholdComponent t={t} />
        </Panel>
      </Collapse>
    </div>
  );
}
