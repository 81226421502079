import React from "react";

import { Result } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";

function NoMatch() {
  return (
    <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
      <Result
        style={{ width: "100%" }}
        title="404"
        subTitle="This page does not exist."
        icon={<QuestionCircleOutlined />}
      />
    </div>
  );
}

export default NoMatch;
