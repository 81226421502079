import React, { useEffect } from 'react';

import { DesktopOutlined, TableOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { Link, useMatch } from 'react-router-dom';

const { Sider } = Layout;

function Sidebar() {
  const [collapsed, setCollapsed] = React.useState<boolean>(true);
  const [selectedKey, setSelectedKey] = React.useState<string>('1');

  let path = useMatch({ path: '/*', end: true });

  useEffect(() => {
    switch (path?.pathname) {
      case '/dashboard':
        setSelectedKey('1');
        break;
      case '/table':
        setSelectedKey('2');
        break;
    }
  }, [path]);

  return (
    <Sider style={{ zIndex: 2, position: 'fixed', height: '100%', top: 0 }} onMouseEnter={() => setCollapsed(false)} onMouseLeave={() => setCollapsed(true)} collapsed={collapsed} theme="light">
      <Menu
        selectedKeys={[selectedKey]}
        mode="inline"
        style={{
          marginTop: '44px',
        }}
      >
        <Menu.Item key="1" icon={<DesktopOutlined />}>
          <Link to="/dashboard">Dashboard</Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<TableOutlined />}>
          <Link to="/table">Responses Table</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
}

export default Sidebar;
