import * as React from 'react';
import { useState } from 'react';
import useResizeObserver from 'use-resize-observer';

import { BellOutlined, SettingOutlined } from '@ant-design/icons';
import { Card, Statistic, Typography } from 'antd';
import tinycolor from 'tinycolor2';
import WidgetButtons from '../common/WidgetButtons';
import { WidgetDataConfig } from '../common/WidgetTypes';
import { RawSurveyResponse } from '../../../scripts/API/APITypes';
import debuglog from '../../../scripts/debugLog';

const { Text } = Typography;

export interface ScoreWidgetConfig extends WidgetDataConfig {
  queues: string[];
  questions: number[];
  agents: string[];
  amberThreshold: number;
  redThreshold: number;
  showAsGreen: boolean;
  amberSound: string;
  redSound: string;
  blink: boolean;
  sla: string;
}

interface ScoreWidgetProps {
  id: string;
  config: ScoreWidgetConfig;
  responseData: RawSurveyResponse[];
  editing: boolean;
  onEditWidgetClicked: any;
  amberColor: string;
  redColor: string;
  greenColor: string;
  queueList: { name: string; id: string }[];
  triggerAlarm: (id: string, alarmSound: string) => void;
  dismissAlarm: (id: string) => void;
}

function ScoreWidget(props: ScoreWidgetProps) {
  const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>();

  const [labelColor, setLabelColor] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [blink, setBlink] = useState(false);

  const [value, setValue] = useState('-');

  const [alarming, setAlarming] = useState<boolean>(false);

  function OnEditWidgetClicked() {
    props.onEditWidgetClicked(props.id);
  }

  React.useEffect(() => {
    if (!value) return;

    const amber = props.config.amberThreshold || 0;
    const red = props.config.redThreshold || 0;
    const checkLess = red < amber;
    const val = parseInt(value);

    if ((red && !checkLess && val >= red) || (checkLess && val <= red)) {
      // Red triggered:
      setLabelColor(props.redColor);
      setBackgroundColor(tinycolor(props.redColor).setAlpha(0.2).toString());
      if (props.config.blink) setBlink(true);
      else setBlink(false);

      if (props.config.redSound !== '') {
        props.triggerAlarm(props.id, props.config.redSound);
        setAlarming(true);
      } else {
        props.dismissAlarm(props.id);
        setAlarming(false);
      }
    } else if ((amber && !checkLess && val >= amber) || (checkLess && val <= amber)) {
      // Amber triggered:
      setLabelColor(props.amberColor);
      setBackgroundColor(tinycolor(props.amberColor).setAlpha(0.2).toString());
      setBlink(false);

      setAlarming(false);
      if (props.config.amberSound !== '') {
        props.triggerAlarm(props.id, props.config.amberSound);
        setAlarming(true);
      } else {
        props.dismissAlarm(props.id);
        setAlarming(false);
      }
    } else {
      // No threshold:
      if (props.config.showAsGreen && value !== '-') {
        setLabelColor(props.greenColor);
        setBackgroundColor(tinycolor(props.greenColor).setAlpha(0.2).toString());
      } else {
        setLabelColor('');
        setBackgroundColor('');
      }
      setBlink(false);
      props.dismissAlarm(props.id);
      setAlarming(false);
    }
  }, [value, props]);

  React.useEffect(() => {
    if (!props.responseData || Object.keys(props.responseData).length === 0) return;

    let count: number = 0;
    let sum: number = 0;

    for (const res of props.responseData) {
      if (!(props.config.queues.length === 0 || props.config.queues.find((v) => v === res.QueueARN))) continue;
      if (!(props.config.questions.length === 0 || props.config.questions.find((v) => v === res.QuestionNumber))) continue;
      if (!(props.config.agents.length === 0 || props.config.agents.find((v) => v === res.AgentId))) continue;

      const score = parseInt(res.Transcript);
      if (!score) continue;

      sum += score;
      count++;
    }

    const avg = Math.floor((sum * 100) / count) / 100;
    debuglog(`Calculated average:`, avg, 'with', sum, '/', count, props.config);

    setValue(avg.toString());
  }, [props.responseData, props.config]);

  return (
    <div ref={ref} style={{ height: '100%', cursor: props.editing ? 'move' : 'default' }} className={`data-widget ${blink ? 'blink' : ''}`}>
      <Card
        bodyStyle={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: backgroundColor,
        }}
      >
        <Text>{props.config.label}</Text>
        <Statistic value={value} style={{ textAlign: 'center' }} valueStyle={{ fontSize: Math.min(width, height) / 4, color: labelColor }} />
        <div hidden={!props.config.amberSound && !props.config.redSound}>
          <BellOutlined style={{ color: labelColor, position: 'absolute', top: '8px', left: '8px' }} className={alarming ? 'ring-icon' : undefined} />
        </div>

        <WidgetButtons editing={props.editing} buttons={[{ icon: <SettingOutlined />, tooltip: 'Edit Widget', onClick: OnEditWidgetClicked }]} />
      </Card>
    </div>
  );
}

export default ScoreWidget;
