import { Button, Space, Tooltip } from 'antd';
import React from 'react';

export interface WidgetButton {
  icon: React.ReactElement;
  tooltip: string;
  onClick: () => void;
}

interface WidgetButtonsProps {
  editing: boolean;
  buttons: WidgetButton[];
}

export default function WidgetButtons(props: WidgetButtonsProps) {
  return (
    <Space direction="vertical" hidden={!props.editing} style={{ position: 'absolute', top: '8px', right: '8px' }}>
      {props.buttons.map((b, i) => {
        return (
          <Tooltip key={i} placement="left" title={b.tooltip}>
            <Button style={{ padding: '4px' }} type="link" onClick={b.onClick}>
              {b.icon}
            </Button>
          </Tooltip>
        );
      })}
    </Space>
  );
}
